import Routers from "./Routes/Routers";

const App = () => {
  return (
    <>
      <Routers />
    </>
  );
};

export default App;
